import * as React from "react";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Link from "@mui/material/Link";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"© "}
      <Link color="inherit" href="https://www.alibux.ca/">
        alibux.ca
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function App() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Familia
          </Typography>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>

      <Box
        component="main"
        sx={{ flex: 1, py: 6, px: 4, bgcolor: "#ffffff", minHeight: "100vh" }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Karibuni. Welcome. Bienvenue. Bienvenido. أهلا بك. 欢迎. स्वागत हे.
          Hoş
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Welcome to the Familia app. This is a place for our family to share
          photos and videos.
        </Typography>
      </Box>
      <Box component="footer" sx={{ p: 2, bgcolor: "#eaeff1" }}>
        <Copyright />
      </Box>
    </Box>
  );
}

export default App;
